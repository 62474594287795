import React from 'react'
import { Link } from 'gatsby'

const yyyy = new Date().getFullYear()

const Footer = () =>
  <footer className="container py-3">
    <div className="text-sm-center small text-muted">
        &copy; Copyright {yyyy} Rina Botha &ndash; <Link to="/">Home</Link> | <Link to="/portfolio/">Portfolio</Link> | <a href="#top">Back to top</a>
    </div>
  </footer>

export default Footer
