import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import Signature from './images/signature'

import book from '../data/books/9780473460327'

import './header.scss'

const Header = ({ siteTitle }) =>
  <header className="site-header sticky-top py-1">
      <div className="container d-flex flex-column flex-sm-row justify-content-between align-items-center p-0">
        <Link className="pt-2" to="/" title={siteTitle}>
          <Signature />
        </Link>

        <nav className="pt-2">
          <Link to="/" title={`My Book - ${book.title}`} activeClassName="link-active">Book</Link>
          <span className="mx-2">&middot;</span>
          <Link to="/portfolio/" title="My Art Portfolio" activeClassName="link-active">Portfolio</Link>
        </nav>

        <nav className="pt-2">
          <SocialIcons />
        </nav>
      </div>
  </header>

const SocialIcons = () =>
  <Fragment>
    <a className="px-2 d-none d-inline-block" href="https://www.facebook.com/ArtByRinaBotha/" title="Art by Rina Botha on Facebook">
      <i className="fab fa-facebook-f" />
      <span className="sr-only">Art by Rina Botha on Facebook</span>
    </a>

    <a className="px-2 d-none d-inline-block" href="https://twitter.com/rinabotha" title="Rina Botha on Twitter">
      <i className="fab fa-twitter" />
      <span className="sr-only">Rina Botha on Twitter</span>
    </a>

    <a className="px-2 d-none d-inline-block" href="https://nz.linkedin.com/in/rinabotha" title="Rina Botha on LinkedIn">
      <i className="fab fa-linkedin-in" />
      <span className="sr-only">Rina Botha on LinkedIn</span>
    </a>

    <a className="px-2 d-none d-inline-block" href="mailto:rinabotha@gmail.com" title="Email Rina Botha">
      <i className="fa fa-envelope" />
      <span className="sr-only">Email Rina Botha</span>
    </a>
  </Fragment>

export default Header
