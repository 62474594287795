import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import SwirlContainer from '../components/container-fluid-swirl'

import book from '../data/books/9780473460327'

const NotFoundPage = () =>
  <Layout>
    <Helmet title="File not found - Rina Botha" />

    <SwirlContainer>
      &nbsp;
    </SwirlContainer>

    <section className="container">
      <div className="m-5" style={{minHeight: "30vw"}}>
        <div className="pb-2 my-4 border-bottom">
          <h1>NOT FOUND</h1>
        </div>

        <p>You just hit a route that doesn't exist...</p>

        <p>I've moved my site around recently with the release of my new
        children's book <Link to="/" className="font-weight-bold">{book.title}</Link>, how ever you can try:</p>

        <ol>
          <li>Visit to the <Link to="/">home page</Link></li>
          <li>Peruse my <Link to="/portfolio/">portfolio</Link></li>
        </ol>
      </div>
    </section>
  </Layout>

export default NotFoundPage
