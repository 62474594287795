import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Picture from 'gatsby-image'

export default () =>
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "signature-430x100.png" }) {
          childImageSharp {
            fixed(width: 213) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data =>
      <Picture fixed={data.image.childImageSharp.fixed} critical={true} fadeIn={false} />
    }
  />
