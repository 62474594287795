import React from "react"
import PicSwirl from './images/swirl'

import './container-fluid-swirl.scss'

export default ({
  children,
}) =>
  <section className="container-fluid SwirlContainer">
    <PicSwirl className="SwirlContainer-background" style={{
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
    }} />

    <div className="container">
      <div className="SwirlContainer-content">
        {children}
      </div>
    </div>
  </section>
