export default {
  isbn: "978-0-473-46032-7",
  title: "Adventure Jock and the treasure map",
  blurb: `
Jock was lucky enough to find an old treasure map in the backyard.
He has decided to go on a treasure hunt to see what he can find. Will
he find treasure? Who will he meet along the way? Check out a sneak
peek of the book.
  `,
  authorName: "Botha, Rina",
  publisherName: "Self published",
  releaseDate: "2018-11",
  dimensions: {
    weight: "120gms",
    height: "250mm",
    width: "200mm",
    depth: "4mm",
  },
  format: "Paperback, 32 pages",
  medium: "Watercolour and colour pencil on paper",
  price: {
    amount: 18.00,
    currency: "NZD",
  },
}
